import React from 'react';
import { Navbar, Container } from 'react-bootstrap';

const Header = () => (
  <Navbar variant="light"  style={{ minHeight: "50px", minWidth: "370px", backgroundColor: "#BD0505"}}>
    <Container style={{backgroundColor: "#BD0505"}}>
      <Navbar.Brand style={{backgroundColor: "#BD0505", fontWeight: 'bold', fontSize: '30px', display: 'flex', alignItems: 'center' }}>
        <a href="https://messageninja.ai">
          <img src="/Logo.png" alt="Logo" style={{height: '50px', }}/>
        </a>
        <a href="https://messageninja.ai"style={{marginTop: '6px', color: 'black', textDecoration: 'none'}}>
          Lead Ninja
        </a>
      </Navbar.Brand>
    </Container>
  </Navbar>
);


export default Header;
