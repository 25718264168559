import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => (
  
  <Container fluid className="text-black text-center p-3" style={{ height: '10vh', backgroundColor: "#BD0505"}}>
   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <p>
        <a href="https://twitter.com/MessageNinjaAi" target="_blank" rel="noopener noreferrer" style={{ color: 'Black', textDecoration: 'none', fontWeight: 'bold' }}>👉 Follow us on Twitter 👈</a> <br/>
      </p>
    </div>
  </Container>
);

export default Footer;
