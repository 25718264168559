// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA84LiMJYOZMN-rZ_hTtiR5itMPqEYrC7c",
  authDomain: "leadninja-c5f70.firebaseapp.com",
  projectId: "leadninja-c5f70",
  storageBucket: "leadninja-c5f70.appspot.com",
  messagingSenderId: "25330336802",
  appId: "1:25330336802:web:92ebe3111e2884398eb67b",
  measurementId: "G-M769MHTG62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app };