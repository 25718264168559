import { Container, Row, Col } from 'react-bootstrap';
import '../index.css';
import React, { useEffect, useState } from 'react';
import { app } from "../firebase";
import { getFirestore, addDoc, collection, setDoc, doc } from "firebase/firestore";

const Dashboard = () => {
  
  const db = getFirestore(app);
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = doc(db, "Users", email);
      await setDoc(docRef, { email: email }, { merge: true });
      alert("Subscription successful!");
      setEmail("");
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error: " + e.message);
    }
  };

  return (
    <Container  style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Row className="w-100">
          <h2 style={{textAlign: 'center'}}>Product hunt leads <strong>in your inbox daily</strong> *no effort required* </h2>
          {/* <hr className="my-4" /> */}
          <br />
          <br />
          <h6 style={{textAlign: 'center'}}>Lead ninja is a <strong>free service</strong> that sends you a daily lead list, with contact information for every launch on product hunt </h6>
          <hr className="my-4" />
          <Col className="mb-3 d-flex justify-content-center">
            <form className="d-flex" style={{width: "80%", maxWidth: "500px"}} onSubmit={handleSubmit}>
              <input type="email" className="form-control" id="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
              <button type="submit" className="btn btn-primary ml-2" style={{marginLeft: "10px", backgroundColor: "#BD0505", border: "none"}}>Subscribe</button>
            </form>
          </Col>
        </Row>
    </Container>
  );
};

export default Dashboard;
